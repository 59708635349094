import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CanonicalService {
  /**
       * Sets the canonical link in the document head.
       * @param url - The canonical URL to set.
       */
  setCanonicalURL(url: string): void {
    // Create a new link element
    let linkElement = document.querySelector("link[rel='canonical']");

    if (!linkElement) {
      linkElement = document.createElement('link');
      linkElement.setAttribute('rel', 'canonical');
      document.head.appendChild(linkElement);
    }

    // Set the href attribute to the canonical URL
    linkElement.setAttribute('href', url);
  }

  /**
  * Removes the canonical link from the document head.
  */
  removeCanonicalURL(): void {
    const linkElement = document.querySelector("link[rel='canonical']");

    if (linkElement) {
      document.head.removeChild(linkElement);
    }
  }
}
