import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    Observable,
    TimeoutError,
    firstValueFrom,
    from,
    fromEvent,
    lastValueFrom,
} from "rxjs";
import { catchError, filter, map, timeout } from "rxjs/operators";
import Swal from "sweetalert2";
import { environment } from "../../environments/environment";
import networkImage from "../constants/network-images";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isInternetPopupOpen = false;

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): any {
        let req = request;
        return next
            .handle(req)
            .pipe(timeout(30000))
            .pipe(
                filter((res) => res instanceof HttpResponse),
                map((res) => {
                    if (this.isInternetPopupOpen) {
                        Swal.close();
                        this.isInternetPopupOpen = false;
                    }
                    return res;
                }),
                catchError(async (error: HttpErrorResponse) => {

                    const code = Number(error.error?.code) || error.status || 503;

                    const isOnline = await this.checkNetwork();
                    if (!isOnline && !this.isInternetPopupOpen) {
                        this.isInternetPopupOpen = true;
                        await Swal.fire({
                            html: networkImage,
                            confirmButtonText: "Close",
                            allowOutsideClick: true,
                            allowEscapeKey: true,
                        });
                        return this.intercept(req, next);

                    } else { }

                    throw new HttpErrorResponse({
                        status: error.status,
                        statusText: error.statusText,
                        error: {
                            code,
                            data: null,
                            message:
                                error.error?.message ||
                                error.error?.msg ||
                                "Server is not responding",
                            timeout: error instanceof TimeoutError || code === 408,
                        },
                    });
                })
            );
    }

    private checkNetwork() {
        return new Promise<boolean>((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = (res) => {
                if (xhr.status === 200) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };
            xhr.onerror = () => {
                resolve(false);
            };
            xhr.open(
                "GET",
                "https://networkconnectivity.googleapis.com/$discovery/rest?version=v1",
                true
            );
            xhr.send();
        });
    }
}
