import { Injectable } from '@angular/core';
import LocalStorage from '../state/local-storage';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class ContactUsFormService {

  constructor(private _router: Router, private localStoarge: LocalStorage,
    private http : HttpClient,
    private readonly loadingService: NgxUiLoaderService
  ) { }


  sendContactDetails(data:any){
    return this.http.post(
      environment.baseUrl+`business/contact`,data
    )
  }

  initiateSubscription : any = [];

  emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  data : any = {
    businessId: window.localStorage.getItem('bId'),
    name: '',
    mobileNo: '',
    email: '',
    message: '',
    moreInfo: {}
  }
  contactUsData(instance:any,successMessage:string){
    // console.log(instance)
    // instance.forEach((element:any) => {
    // });
    this.initiateSubscription.push(instance);
    this.loadingService.start('scroll');
    for (let index = 0; index < instance.length; index++) {
      if(instance[index].required){
        if(instance[index].inputValue === undefined || instance[index].inputValue === ""){
          console.log('Please fill all mandatory fields')
        }
      }
      else{
        if(instance[index].label.includes("Email")){
          if(!this.emailRegex.test(instance[index].inputValue)){
            console.log("Please Enter Valid email id")
          }
        }
      }
    }
    for (let index = 0; index < instance.length; index++) {
      if(instance[index].label.includes("Name")){
        this.data.name = instance[index].inputValue
      }
      else if(instance[index].label.includes("Email")){
        this.data.email = instance[index].inputValue
      }
      else if(instance[index].label.includes("Message")){
        this.data.message = instance[index].inputValue
      }
      else if(instance[index].label.includes("Phone")){
        this.data.mobileNo = instance[index].inputValue
      }
      else{
        let key = instance[index].label
        let value = instance[index].inputValue
        this.data.moreInfo[key] = value
      }

    }
    this.data.businessId = window.localStorage.getItem('bId')
    this.sendContactDetails(this.data).subscribe(
      (res:any) => {
        // console.log(res);
        this.loadingService.stop("scroll");
        Swal.fire({
          title: `<strong>${successMessage}</strong>`,
          icon: "success",
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: `
            <i class="fa"></i> Done
          `,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            for (let index = 0; index < instance.length; index++) {
              instance[index].inputValue = ""
            }
          }
          this.initiateSubscription.forEach((element:any) => {
            element.unsubscribe()
          });
        });
      },
      (err) => {
        // console.log(err);
        this.initiateSubscription.forEach((element:any) => {
          element.unsubscribe()
        });
        this.loadingService.stop("scroll");
      }
    )
  }
}
