import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicPickupService } from '../../services/dynamic-pickup.service';
import { HomeService } from '../../services/home.service';
import { Subscription } from 'rxjs';
import { NavbarRedirectionsService } from '../../services/navbar-redirections.service';
import { CommonButtonRedirectionService } from '../../services/common-button-redirection.service';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderModule, NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: 'app-view-blog',
  standalone: true,
  imports: [NgxUiLoaderModule],
  templateUrl: './view-blog.component.html',
  styleUrl: './view-blog.component.scss'
})
export class ViewBlogComponent {
  @ViewChild('websiteStructure', { read: ViewContainerRef })
  websiteStructure: ViewContainerRef | any;

  constructor(
    private _pickupService: DynamicPickupService,
    private homeService: HomeService,
    private _navbarRedirections : NavbarRedirectionsService,
    private _commonButtonRedirection : CommonButtonRedirectionService,
    private activatedRoute: ActivatedRoute,
    private readonly loadingService: NgxUiLoaderService
  ){}

  blogId: any;
  ngOnInit(){
    this.loadingService.start('scroll');
    this.activatedRoute.params.subscribe((res: any) => {
      this.blogId = res.slug;
      // console.log(res.slug)
    });
    this.getPageDetails()
  }

  ngOnDestroy() {
    this.subscription.forEach((element: any) => {
      element?.unsubscribe();
    });
  }

  getPageDetails(){
    this.homeService.getHeaderAndFooter().subscribe((res: any) => {
      res.data[0].content.navbarButtons.forEach((btn:any) => {
        if(btn.label.toLowerCase().includes('blog')){
          btn.status = true
        }
        else{
          btn.status = false;
        }
      });
      let section : any[] = [res.data[0]];
      let blogSection = {
        sectionType : 'viewBlog',
        blogId : this.blogId
      }
      section.push(blogSection);
      section.push(res.data[1]);
      const instance = this._pickupService.createDynamicComponents(this.websiteStructure, section, false);
      this.initiateInstance(instance);
      this.loadingService.stop('scroll');
    }, () => {
      this.loadingService.stop('scroll');
    })
  }

  subscription: Subscription[] = [];
  initiateInstance(instance: any) {
    this.subscription.forEach((element: any) => {
      element?.unsubscribe();
    });
    this.subscription.push(
      instance?.pageRedirectionButton.subscribe((res: any) => {
        // console.log(res)
        this._navbarRedirections.pageRedirection(res.data);
      })
    );
  }
}
