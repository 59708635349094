import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import LocalStorage from '../state/local-storage';

@Injectable({
  providedIn: 'root'
})
export class CommonButtonRedirectionService {

  constructor(private _router: Router, private localStoarge: LocalStorage) { }

  commonButtonRedirection(instance: any) {
    //if instance type external
    if (instance.linkType.includes('External')) {
      if (instance.newTab) {
        window.open(instance.redirectionUrl, "_blank")
      }
      else {
        window.location.href = instance.redirectionUrl
      }
    }

    //if instance type page
    //need to change model for page because of need page name also
    if (instance.linkType.includes("Page")) {
      let redirectionUrl = window.location.origin+instance?.redirectionUrl
      if (instance.newTab) {
        window.open(redirectionUrl,'_blank');
        this.localStoarge.set('pId', instance.pageId);
      }
      else {
        this._router.navigate([`/${instance.redirectionUrl}`]);
        this.localStoarge.set('pId', instance.pageId);
      }

      setTimeout(() => {
        window.location.reload();
      }, 100);
    }

    //if instance type email
    if (instance.linkType.includes("Email")) {
      const email = instance.redirectionUrl;
      const mailtoUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${encodeURIComponent(email)}`;
      if (instance.newTab) {
        window.open(mailtoUrl, "_blank")
      }
      else {
        window.location.href = mailtoUrl
      }
    }

    //if instance type phone
    if (instance.linkType.includes("Phone")) {
      const phoneNumber = instance.redirectionUrl;  // Phone number to be called
      const telUrl = `tel:${phoneNumber}`;
      if (instance.newTab) {
        window.open(telUrl, "_blank")
      }
      else {
        window.location.href = telUrl
      }
    }

    //if instance type section
    if (instance.linkType.includes("Section")) {
      let allIds : any = [];
      const allElements = document.querySelectorAll('*');
      if (instance.newTab) {
        window.open(window.location.href,'_blank');
        setTimeout(() => {
          allElements.forEach(element => {
            // Check if the element has an 'id' attribute
            if (element.id == instance.pageId) {
              // Add the ID to the array
              // allIds.push(element.id);
              let id = document.getElementById(instance.pageId);
              id?.scrollIntoView({
                behavior:'smooth',
                block:'center'
              })
            }
          });
        }, 2000);
      }
      else {
        allElements.forEach(element => {
          // Check if the element has an 'id' attribute
          if (element.id == instance.pageId) {
            // Add the ID to the array
            // allIds.push(element.id);
            let id = document.getElementById(instance.pageId);
            id?.scrollIntoView({
              behavior:'smooth',
              block:'center'
            })
          }
        });
      }
    }
  }

}
