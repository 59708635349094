import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilsService {

  constructor() { }

  generateUUID(): string {
    let uuid = '';
    const chars = '0123456789abcdef';
  
    for (let i = 0; i < 36; i++) {
      if (i === 8 || i === 13 || i === 18 || i === 23) {
        uuid += '-';
      } else if (i === 14) {
        uuid += '4';
      } else if (i === 19) {
        uuid += chars.charAt(Math.floor(Math.random() * 4) + 8);
      } else {
        uuid += chars.charAt(Math.floor(Math.random() * chars.length));
      }
    }
  
    return uuid;
  }
}
