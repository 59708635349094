// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // baseUrl:'https://api.simpo.ai/',
    baseUrl:'https://dev-api.simpo.ai/',
    baseBusinessUrl :"https://dev-business.simpo.ai/",
  
    scriptSrcFile : "https://durga250720.github.io/blogs/blogs.js",
    firebase: {
      apiKey: "AIzaSyBr1I-H_4GhfPOv_M0xyQmLLGRvP3ig0dM",
      authDomain: "naturals-fb95e.firebaseapp.com",
      projectId: "naturals-fb95e",
      storageBucket: "naturals-fb95e.appspot.com",
      messagingSenderId: "1025445649680",
      appId: "1:1025445649680:web:e98e31a6bcd81634561995",
      measurementId: "G-ND805TS8V0"
    }
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  
  
  // its in angular.json 
  
  
  // "outputHashing": "all",
  // "buildOptimizer": true,
  // "optimization": true,
  // "vendorChunk": false,
  // "extractLicenses": true,
  // "sourceMap": false,
  // "namedChunks": false,
  // "aot": true