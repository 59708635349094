import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { BlankComponent } from './pages/blank/blank.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { ViewBlogComponent } from './pages/view-blog/view-blog.component'

export const routes: Routes = [
    {
        path:'',
        component:HomeComponent
    },
    {
      path : 'blog/:slug',
      component: ViewBlogComponent
    },
    {
        path:'blog',
        component:BlogsComponent
    },
    {
        path:':pageName',
        component:BlankComponent
    }
];
