import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { HomeService } from '../../services/home.service';
import { DynamicPickupService } from '../../services/dynamic-pickup.service';
import { NavbarRedirectionsService } from './../../services/navbar-redirections.service';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import LocalStorage from '../../state/local-storage';
import { CommonButtonRedirectionService } from '../../services/common-button-redirection.service';
import { ContactUsFormService } from '../../services/contact-us-form.service';
import { CanonicalService } from '../../services/canonical.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blogs',
  standalone: true,
  imports: [NgxUiLoaderModule],
  templateUrl: './blogs.component.html',
  styleUrl: './blogs.component.scss'
})
export class BlogsComponent {
  @ViewChild('websiteStructure', { read: ViewContainerRef })
  websiteStructure: ViewContainerRef | any;
  subscription: Array<unknown> = [];
  pageId : any;
  constructor(
    private _homeService: HomeService,
    private _pickupService: DynamicPickupService,
    private _navbarRedirections: NavbarRedirectionsService,
    private readonly loadingService: NgxUiLoaderService,
    private localStorage: LocalStorage,
    private _commonButtonRedirection : CommonButtonRedirectionService,
    private _contactusFormService : ContactUsFormService,
    private _canonicalService : CanonicalService,
    private route: ActivatedRoute,
    private metaTagService: Meta,
    private titleService: Title,
    private router: Router
  ) {}

  pagechagedSubscription : any = '';
  ngOnInit(): void {
    this.loadingService.start('scroll');
    this.getPageDetails();
    // this.pagechagedSubscription = this._navbarRedirections.pageChanged.subscribe((res) => {
    //   if(res.data){
    //     this.getPageDetails();
    //   }
    // })
  }

  component: any[] = []
  getPageDetails() {
    let id = this.localStorage.get('pId');
    this._homeService.getPageDataWithId(id).subscribe({
      next: (data:any) => {
        if(data){
          const instance: any = this._pickupService.createDynamicComponents(
            this.websiteStructure,
            data.components,
            false
          );
          this.component = data.components;
          this.initiateInstance(instance);
          this.pageId = data?.id;
          this.startAnalytics(data?.pageName,data?.id);
          this._homeService.pageHilighter(data)
          this.titleService.setTitle(data?.seoData?.metaTitle);
          this.metaTagService.updateTag({ name: 'description', content: data?.seoData?.metaDescription });
          this.metaTagService.updateTag({ property: "og:image", content: data?.seoData?.image });
          this.metaTagService.updateTag({ property: "og:description", content: data?.seoData?.metaDescription });
          if(data?.websiteContentV3?.seoData?.keywords != null){
            this.metaTagService.updateTag({ name: 'keywords', content: data?.seoData?.keywords })
          }
          if(data?.seoData?.canonical == "1"){
            this.route.url.subscribe(() => {
              // Generate the canonical URL based on the current route
              const canonicalURL = this.generateCanonicalURL();
              // console.log(canonicalURL)

              // Set the canonical URL
              this._canonicalService.setCanonicalURL(canonicalURL);
          });
          }
          if(data?.seoData?.canonical == "0"){
            this._canonicalService.removeCanonicalURL();
          }
        }
      },
      error: (err) => {
        this.loadingService.stop('scroll');
        console.log(err);
      },
    });
  }

  generateCanonicalURL(){
    const baseUrl = window.location.origin; // Use the base URL of the application
    const routePath = this.route.snapshot.url.join('/');
    return `${baseUrl}/${routePath}`;
 }


  initiateInstance(instance: any) {
    this.subscription.forEach((element: any) => {
      element?.unsubscribe();
    });
    // console.log(instance)
    this.subscription.push(
      instance?.pageRedirectionButton.subscribe((res: any) => {
        // console.log(res)
        this._navbarRedirections.pageRedirection(res?.data);
      })
    );
    this.subscription.push(
      instance?.buttonRedirection.subscribe((res:any) => {
        this._commonButtonRedirection.commonButtonRedirection(res.data);
      })
    )
    this.subscription.push(
      instance?.contactFormData.subscribe((res:any) => {
        this._contactusFormService.contactUsData(res.data,res.successMessage);
      })
    )
    this.subscription.push(
      instance.blogByIdEvent.subscribe((res: any) => {
        // this.router.navigate(['/view-blog'], {queryParams : {blogId: res}});
        this.router.navigate([`/blog/${res}`])
      })
    )
    this.subscription.push(
      instance.openBlogListEvent.subscribe((res: any) => {
        this.redirectToBlogList();
      })
    )
    this.loadingService.stop('scroll');
  }

  redirectToBlogList(){
    for(let navbar of this.component[0].content.navbarButtons) {
      if(!navbar.folder) {
        if(navbar.redirectionUrl === '/blog') {
          this._navbarRedirections.pageRedirection(navbar);
        }
      } else {
        for(let page of navbar?.folder?.pages) {
          if(page.redirectionUrl === '/blog') {
            this._navbarRedirections.pageRedirection(page);
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this.endAnalytics();
    this.subscription.forEach((element: any) => {
      element?.unsubscribe();
    });
  }

  startAnalytics(pageName:any,pageId:any){
    // console.log(data);
    this._homeService.startSession(pageName,pageId).subscribe({
      next:(data)=>{
        // console.log(data);
      },
      error:(err) => {
        console.log(err);
      }
    })
  }

  endAnalytics(){
    this._homeService.endSession(this.pageId).subscribe(
      {
        next:(data) => {
          console.log()
        },
        error:(err) => {
          console.log(err)
        }
      }
    )
  }
}
