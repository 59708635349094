import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { EventsService } from 'simpo-component-library';

@Injectable({
    providedIn: 'root',
})
export class VoiceRecognitionService {

    private recognition: SpeechRecognition | null = null;
    private $result: Subject<string> = new Subject<string>();

    constructor(
        private readonly router: Router
    ) {

    }
    ngOnDestroy(): void {

    }
    initialize() {
        const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
        if (SpeechRecognition) {
            this.recognition = new SpeechRecognition();
            if (!this.recognition)
                return;
            this.recognition.lang = 'en-US';
            this.recognition.interimResults = false;
            this.recognition.maxAlternatives = 1;

            this.recognition.onresult = (event) => {
                const transcript = event.results[0][0].transcript;
                console.log("tran: ", transcript)
                this.$result.next(transcript);
                this.router.navigate(['/list'], { queryParams: { searchTxt: transcript } });
            };

            this.recognition.onerror = (event) => {
                console.error('Speech recognition error:', event.error);
            };
        } else {
            console.warn('Speech recognition is not supported in this browser.');
        }
    }

    startListening() {
        if (this.recognition) {
            this.recognition.start();
        }
    }

    stopListening() {
        if (this.recognition) {
            this.recognition.stop();
        }
    }

    get getTranscript(): Observable<string> {
        return this.$result as Observable<string>;
    }
}