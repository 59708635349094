import { AfterViewInit, Component, HostListener, isDevMode, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
// import { EventsService } from 'simpo-component-library';
import { HomeService } from './services/home.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EventsService } from 'simpo-component-library';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonModule } from '@angular/common';
import { cashfreeSandbox, cashfreeProd } from "cashfree-pg-sdk-javascript";
import { MessagingService } from './services/messaging.service';
import { VoiceRecognitionService } from './services/voice-recognition.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, NgxUiLoaderModule, MatIconModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'simpo-user';
  public isLoading: boolean = false;
  public showBag: boolean = false;
  public overlayPreview: boolean = false;
  public isPWAInstalled: boolean = false;

  constructor(
    private readonly _eventService: EventsService,
    private readonly loadingService: NgxUiLoaderService,
    private readonly router: Router,
    private readonly messageService: MessagingService,
    private readonly voiceRecognition: VoiceRecognitionService
  ) { }

  speechText: string = '';
  showSpeakingOverlay: boolean = false;
  ngOnInit(): void {

    window.addEventListener('beforeinstallprompt', (event: any) => {
      event.preventDefault();
    });
    this.checkIfInstalled();

    this.voiceRecognition.getTranscript.subscribe((response)=> {
      this.speechText = response;
      setTimeout(()=> {
        this.speechText = '';
      }, 1000);
    })
    this.messageService.requestToken();
    this.messageService.receiveMessage();
    this.messageService.currentMessage.subscribe(
      (message) => {
        console.log("Notification Recived");
      }
    );

    localStorage.setItem("REQUEST_FROM", "USER");
    if (environment.production)
      this._eventService.environmentType.emit("PROD");
    else
      this._eventService.environmentType.emit("DEV");
    this._eventService.showLoadingScreen.subscribe((response) => {
      this.isLoading = response && this.isEcommerceWebsite;
      if (this.isLoading)
        this.loadingService.start('initLoader');
      else
        this.loadingService.stop('initLoader');
    })
    this._eventService.showBagIcon.subscribe((response: boolean) => this.showBag = response && (localStorage.getItem("websiteType") == "E_COMMERCE") && window.innerWidth > 475 && !(window.location.href.includes("profile") || window.location.href.includes("cart")));
    this._eventService.cashFreeEvent.subscribe((response: any) => {
      const paymentSessionId = response.data.orderSignature;
      localStorage.setItem("orderId", response.data.pgOrderId);
      let cashFree;
      if (environment.production) {
        cashFree = new cashfreeProd.Cashfree(paymentSessionId);
      } else {
        cashFree = new cashfreeSandbox.Cashfree(paymentSessionId);
      }
      localStorage.setItem("paymentFor", "CART_PAYMENT");
      cashFree.redirect();

    })
  }
  checkIfInstalled() {
    this.isPWAInstalled = window.matchMedia('(display-mode: standalone)').matches;
  }
  goToCart() {
    this.router.navigate(['/cart']);
  }
  closeOverlay() {
    this.overlayPreview = false;
  }

  speakingSound() {
    var sound = new  Audio("assets/sound/btn-click-sound.mp3");
    sound.play();
  }

  public isSpeaking: boolean = false;
  toggleSpeech() {
    if (window.innerWidth < 475 && !this.showSpeakingOverlay) {
      this.showSpeakingOverlay = !this.showSpeakingOverlay;
      return;
    }
    this.speakingSound();
    setTimeout(()=> {
      if (this.isSpeaking) {
        this.isSpeaking = false;
        this.showSpeakingOverlay = false;
        this.speechText = '';
        this.voiceRecognition.stopListening();
      } else {
        this.voiceRecognition.initialize();
        this.isSpeaking = true;
        this.speechText = 'Listing...';
        this.voiceRecognition.startListening();
        setTimeout(()=> {
          if (this.isSpeaking)
            this.toggleSpeech();
        }, 10000);
      }
    }, 500);
  }

  get isMobile(): boolean {
    return window.innerWidth < 475;
  }
  get isEcommerceWebsite(): boolean {
    return localStorage.getItem("websiteType") == "E_COMMERCE";
  }
}
